.video-options {
  background-color: #c4c4c4;
  display: flex;
  flex-direction: row-reverse;
  margin-top: -10px;
}

* + .ql-video {
  margin-top: 1em !important;
  clear: both;
}

.ql-video iframe {
  border: none;
}
