@import '@theme/vars';
@import '@theme/reset';
@import '@theme/carousel';

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Toastify__toast {
  margin-right: 12px;
}

.Toastify__close-button.Toastify__close-button--colored {
  margin-left: -10px;
  color: #707070;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  background: #43a047 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 120%;
  padding: 10px;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  background-color: #f2dddd !important;
  color: #eb3846 !important;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
}

.Toastify__toast-body {
  padding: 0px !important;
}

.MuiDialog-paperWidthSm {
  max-width: 720px !important;
}

.Toastify__toast-container {
  z-index: 1199 !important;
  width: 300px;
  padding: 0;
  max-height: calc(100% - 100px);
  margin-bottom: 110px;
  margin-right: -15px;
  overflow-y: auto;
  overflow-x: hidden;

  @media screen and (max-width: 840px) {
    max-height: calc(100% - 190px);
    margin-bottom: 170px;
  }

  @media screen and (max-width: 540px) {
    max-height: calc(100% - 245px);
    margin-bottom: 225px;
    z-index: 1200 !important;
  }

  @media screen and (max-width: 480px) {
    width: 322px !important;
    padding: 5px !important;
    max-height: calc(100% - 245px) !important;
    margin-right: -20px !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
    margin-bottom: 225px !important;
    right: 18px !important;
    left: auto !important;
    bottom: 15px !important;
  }
}
// Стили для уведомлений справа внизу
.Toastify__toast-container.Toastify__toast-container--bottom-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: auto !important;
  min-width: 300px !important;
  max-width: 500px !important;
  gap: 15px;
  @media screen and (max-width: 840px) {
    margin-bottom: 170px !important;
  }
}

.Toastify__toast-container.Toastify__toast-container--bottom-right .Toastify__toast-theme--colored {
  margin-bottom: 0;
}

// Стили для третьего Toast контейнера
.Toastify__toast-container.Toastify__toast-container--bottom-center {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 840px) {
    margin-bottom: 160px !important;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 140px !important;
  }
}
.Toastify__toast.Toastify__toast-theme--colored.nn-toast {
  background-color: #e2ffe5 !important;
  justify-content: center;
  width: fit-content;
  color: #048e11;
  padding: 10px;
  gap: 44px;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 500;
  white-space: nowrap;
  line-height: 21px;

  @media (max-width: 480px) {
    margin-bottom: 16px;
    white-space: normal;
    padding: 12px;
    border-radius: 4px;
  }
}
