$screen-xl: 1920px;
$screen-l: 1360px;
$screen-m: 1024px;
$screen-s: 768px;
$screen-s100: 868px;
$screen-s200: 968px;
$screen-xs: 360px;
$screen-xs100: 460px;
$screen-xs200: 560px;
$screen-xs300: 660px;

$font-main: roboto, 'Segoe UI', 'SF UI Display', sans-serif;
$font-legacy: 'Segoe UI', Roboto, -apple-system, BlinkMacSystemFont, Oxygen, Ubuntu, Cantarell, 'Fira Sans',
  'Droid Sans', 'Helvetica Neue', sans-serif;

$main-text-color: #222;
$second-text-color: #4e4e4e;
$blue-color: #1964e7;
$light-blue-color: #e6efff;
$light-gray-color: #c4c4c4;
$gray-color: #707070;
$divider-color: #dfdfdf;
$red-color: #c31e28;
$black80: #292d37;
$grey-editor: #e8e8e8;
$grey100: #4e4e4e;
