@keyframes tonext {
  75% {
    left: 0;
  }
  95% {
    left: 100%;
  }
  98% {
    left: 100%;
  }
  99% {
    left: 0;
  }
}

@keyframes tostart {
  75% {
    left: 0;
  }
  95% {
    left: -300%;
  }
  98% {
    left: -300%;
  }
  99% {
    left: 0;
  }
}

@keyframes snap {
  96% {
    scroll-snap-align: center;
  }
  97% {
    scroll-snap-align: none;
  }
  99% {
    scroll-snap-align: none;
  }
  100% {
    scroll-snap-align: center;
  }
}

.carousel {
  position: relative;
  padding-top: 78%;

  & img.change-item,
  & img.delete-item {
    z-index: 3;
  }

  @media screen and (max-width: $screen-s200) {
    padding-top: 79%;
  }
  @media screen and (max-width: $screen-s100) {
    padding-top: 80%;
  }
  @media screen and (max-width: $screen-s) {
    padding-top: 81%;
  }
  @media screen and (max-width: $screen-xs300) {
    padding-top: 86%;
  }
  @media screen and (max-width: $screen-xs200) {
    padding-top: 90%;
  }
  @media screen and (max-width: $screen-xs100) {
    padding-top: 95%;
  }
  @media screen and (max-width: $screen-xs) {
    padding-top: 100%;
  }
}

.carousel_viewport {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  overflow-x: scroll;
  counter-reset: item;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.carousel_slide {
  position: relative;
  flex: 0 0 100%;
  width: 100%;
  // background-color: #f99;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // background-size: cover;
  // background-repeat: no-repeat;
  // background-position: center;
}

// .carousel_slide:nth-child(even) {
//   background-color: #99f;
// }

.carousel_slide:before {
  content: counter(item);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -40%, 70px);
  color: #fff;
  font-size: 2em;
}

.carousel_snapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-color: $black80;

  & .carousel_snapper_image {
    width: 100%;
    padding-top: 69.44%;
  }

  & .carousel_snapper_info {
    position: absolute;
    top: 11px;
    left: 8px;
    width: auto;
    height: 25px;
    padding: 4px 12px;
    border-radius: 19.54px;
    gap: 9.49px;
    font-size: 13.29px;
    font-weight: 400;
    line-height: 120%;
    color: #ffffff;
    background-color: $black80;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.8;
  }

  & .carousel_snapper_author {
    width: 100%;
    padding-top: 4px;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: $grey100;
    background-color: #ffffff;
  }

  & .carousel_snapper_description {
    width: 100%;
    padding-top: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: $main-text-color;
    background-color: #ffffff;
  }

  @media screen and (max-width: $screen-s) {
    .carousel_snapper_author {
      font-size: 12px;
    }
  }
}

// @media (hover: hover) {
//   .carousel_snapper {
//     animation-name: tonext, snap;
//     animation-timing-function: ease;
//     animation-duration: 4s;
//     animation-iteration-count: infinite;
//   }

//   .carousel_slide:last-child .carousel_snapper {
//     animation-name: tostart, snap;
//   }
// }

// @media (prefers-reduced-motion: reduce) {
//   .carousel_snapper {
//     animation-name: none;
//   }
// }

// .carousel:hover .carousel_snapper,
// .carousel:focus-within .carousel_snapper {
//   animation-name: none;
// }

.carousel_navigation {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  text-align: center;
}

.carousel_navigation-list,
.carousel_navigation-item {
  display: inline-block;
}

.carousel_navigation-button {
  display: inline-block;
  width: 1.5rem;
  height: 1.5rem;
  background-color: #333;
  background-clip: content-box;
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  transition: transform 0.1s;
  z-index: 2;
}

.carousel::before,
.carousel::after,
.carousel_prev,
.carousel_next {
  position: absolute;
  top: 45%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 0;
  outline: 0;
  z-index: 2;

  cursor: pointer;
}

.carousel::before,
.carousel_prev {
  left: 10px;
}

.carousel::after,
.carousel_next {
  right: 10px;
}

.carousel::before,
.carousel::after {
  content: '';
  z-index: 1;
  background-color: #333;
  background-size: 1.5rem 1.5rem;
  background-repeat: no-repeat;
  background-position: center center;
  color: #fff;
  font-size: 2.5rem;
  line-height: 4rem;
  text-align: center;
  pointer-events: none;
}

.carousel::before {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='0,50 80,100 80,0' fill='%23fff'/%3E%3C/svg%3E");
  z-index: 3;
}

.carousel::after {
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 100 100' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolygon points='100,50 20,100 20,0' fill='%23fff'/%3E%3C/svg%3E");
  z-index: 2;
}
